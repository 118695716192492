.btn {
  position: relative;
  min-height: 43px;
  border-radius: 8px;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--golos-text);
  cursor: pointer;
}

.yellow{
  background: var(--primary-yellow);
  color: var(--primary-black);
}

.gray{
  background: var(--primary-gray);
  color: var(--white-color)
}

.green{
  background: #1b2a22;
  color: #28c76f;
}

.transparent{
  padding: 0;
  background: transparent;
  font-weight: 600;
  color: var(--white-color);
  border: none;
}

.yellowBorder{
  border: 2px solid var(--primary-yellow);
  background: transparent;
  font-weight: 600;
  color: var(--primary-yellow);
}