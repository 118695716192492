.progressWrapper{
  width: 298px;
  margin-top: 16px;
}

.progressInner{
  width: 100%;
  height: 3px;
  background: var(--border-color);
  border-radius: 8px;
}

.progress{
  border-radius: 8px;
  height: 3px;
}

.yellowProgress{
  background: var(--primary-yellow);
}

.greenProgress{
  background: #28C76F;
}

.progressValues{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;

  & span{
    font-family: var(--golos-text);
    font-size: 12px;
    font-weight: 50px;
    line-height: 11.28px;
  }
}

.currentValue{
  color: var(--white-color)
}

.maxValue{
  color: rgba(238, 238, 239, .6)
}

@media(max-width: 490px){
  .progressWrapper{
    width: 100%;
  }
}