.errorInfo{
  font-family: var(--golos-text);
  font-weight: 500;
  color: #EC1515;
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  text-align: center;

  & a{
    color: inherit;
  }
}

.errorDescr{
  font-size: 20px;
  margin-bottom: 10px;
}