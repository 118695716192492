.mainWrapper,
.documentsBlock {
  margin-top: 40px;
}

.titleSpan {
  color: var(--primary-yellow);
}

.documentsBlock, .paymentsBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}

.blockTitle {
  font-family: var(--golos-text);
  font-weight: 600;
  font-size: 16px;
  line-height: 101%;
  color: var(--white-color);
  margin-right: auto;
}

.blockBtn{
  width: 74%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border: 1px solid var(--border-color);
}

.paymentsBlock{
  margin-top: 40px;
}

.preloader{
  display: block !important;
  margin: 0 auto !important;
  margin-top: 250px !important;
}

.mainError{
  margin-top: 250px;
}


@media(max-width: 490px){
  .documentsBlock, .paymentsBlock{
    width: 100%;
  }

  .blockBtn{
    width: 100%;
  }
}

@media(max-width: 437px){
  .documentsBlock, .paymentsBlock{
    align-items: center;
  }

  .blockBtn{
    width: 100%;
  }
}