.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userInfo {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  & span {
    font-family: var(--golos-text);
    font-weight: 500;
    font-size: 14px;
    line-height: 111%;
    color: var(--primary-yellow);
  }
}

.headerBtn{
  min-height: 0 !important;
}
