.authWrapper, .authBlock{
  margin-top: 40px;
}

.authBlock{
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.authError{
  margin-top: 30px;
}