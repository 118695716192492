.installBtn{
  width: 331px;
  margin-top: 130px;
}

.notification {
  background: #1e1e1e;
  border: 1px solid #404040;
  padding: 20px 20px;
  margin-top: 40px;
  display: block;
  width: 328px;
  border-radius: 8px;

  p {
    max-width: 300px;
    font-family: var(--golos-text);
    font-size: 14px;
    font-weight: 600;
    color: var(--white-color);
  }

}

.notificationList{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-family: var(--golos-text);
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-yellow);
  margin-top: 10px;
}

.instructionTitle{
  font-family: var(--golos-text);
  font-weight: 600;
  font-size: 18px;
  color: var(--white-color);
  margin-bottom: 20px;
}

@media (max-width: 490px) {
  .notification {
    width: 100%;
  }

  .installBtn{
    width: 100%;
  }
}