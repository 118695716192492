.contentBox {
  position: relative;
  border: 1px solid var(--border-color);
  background: var(--primary-gray);
  border-radius: 8px;
  padding: 16px;
  width: 328px;
  min-height: 88px;
  cursor: pointer;
}

.contentInner {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  & h3 {
    font-family: var(--golos-text);
    font-weight: 500;
    font-size: 18px;
    line-height: 94%;
    color: var(--white-color);
  }
}

.paymentInfo {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-top: 12px;
  margin-bottom: 12px;

  & h3 {
    font-family: var(--golos-text);
    font-weight: 500;
    font-size: 18px;
    line-height: 94%;
    color: var(--white-color);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.boxArrow {
  position: absolute;
  top: 20px;
  right: 20px;
}

.documentIcon{
  border-radius: 8px;
}

@media(max-width: 490px){
  .contentBox, .wrapper{
    width: 100%;
  }
}

@media(max-width: 360px){
  .contentBox{
    width: 100%;
    min-width: 308px;
  }

  .contentInfo, .paymentInfo{
    & h3{
      font-size: 16px;
    }
  }
}

@media(max-width: 340px){
  .contentBox{
    width: 100%;
    min-width: 293px;
  }
}

@media(max-width: 330px){
  .contentBox{
    width: 100%;
    min-width: 273px;
  }

  .contentInfo, .paymentInfo{
    & h3{
      font-size: 14px;
    }
  }
}
