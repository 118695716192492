.label {
  width: max-content;
  font-family: var(--golos-text);
  font-weight: 500;
  font-size: 12px;
  line-height: 94%;
  border-radius: 8px;
  padding: 6px 8px;
}

.yellow {
  background: rgba(255, 214, 0, .20);
  color: var(--primary-yellow);
}

.green {
  background: rgba(40, 199, 111, .10);
  color: #28c76f;
}

.gray{
  background: #aaaaaa;
  color: var(--primary-black);
}
