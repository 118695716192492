.checkboxWrapper {
  display: flex;
  width: max-content;
  position: relative;
}

.checkbox {
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  position: absolute;
}

.checkbox:checked + .checkSpan{
  background-color: var(--primary-yellow);
  background-image: url('../../../assets/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}

.checkSpan{
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #F6F7F9;
}

.error{
  border: 1px solid red !important
}
