.verifyWrapper {
  margin-top: 40px;
}

.verifyBtn {
  padding-left: 10px;
  margin-bottom: 10px;

  & svg {
    position: absolute;
    top: 19px;
    left: 0px;
  }
}

.verifyDescr {
  font-family: var(--golos-text);
  font-size: 14px;
  line-height: 101%;
  margin-top: 10px;
  color: var(--white-color);
}

.codeBlock {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  & div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 30px;
  }
}

.verifyInfo {
  max-width: 328px;
  font-family: var(--golos-text);
  font-size: 14px;
  line-height: 101%;
  color: var(--white-color);
  margin-top: 40px;

  & span{
    font-weight: 700;
  }
}

.verifyLink{
  color: var(--white-color)
}