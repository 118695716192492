.input {
  border-radius: 16px;
  padding: 10px 20px;
  width: 100%;
  height: 53px;
  border: 1px solid var(--border-color);
  background: transparent;
  outline: none;
  caret-color: transparent;
  font-family: var(--golos-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 33.3px;
  color: var(--white-color);
}

.placeholderColor{
  &::placeholder{
    color: var(--white-color);
  }
}

.error{
  &::placeholder{
    color: #EC1515;
  }
}