.registerWrapper {
  margin-top: 40px;
}

.registerBlock {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 75px;
}

.registerError{
  font-family: var(--golos-text);
  color: red;
  margin-top: 30px;
}

.approvalBox{
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.approvalText{
  font-family: var(--golos-text);
  font-size: 14px;
  font-weight: 500;
  color: var(--white-color);

  & span{
    color: var(--white-color);
    text-decoration: underline;
    text-underline-offset: 0.1rem;
    text-decoration-skip-ink: none;
    cursor: pointer;
  }
}