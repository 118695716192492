.successWrapper {
  margin-top: 40px;
}

.successBtn {
  padding-left: 10px;
  margin-bottom: 10px;

  & svg {
    position: absolute;
    top: 19px;
    left: 0px;
  }
}

.titleBox {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 40px;
}

.notification {
  background: #1e1e1e;
  border: 1px solid #404040;
  padding: 20px 20px;
  margin-top: 40px;
  display: block;
  width: 328px;
  border-radius: 8px;

  p {
    max-width: 300px;
    font-family: var(--golos-text);
    font-size: 14px;
    font-weight: 600;
    color: var(--white-color);
  }

  & button {
    width: 100%;
    margin-top: 20px;
  }
}

.iosInfo {
  font-family: var(--golos-text);
  margin-top: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 14px;
  font-weight: 600;

  & ol{
    border-bottom: 1px solid var(--white-color);
    padding-bottom: 10px;

    &:last-child{
      border-bottom: none
    }
  }
}

.notificationList{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-family: var(--golos-text);
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-yellow);
  margin-top: 10px;
}

@media (max-width: 490px) {
  .notification {
    width: 100%;
  }
}
