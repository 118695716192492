:root{
  --primary-black: #050505;
  --primary-yellow: #ffd600;
  --primary-gray: #1e1e1e;
  --white-color: #ffffff;
  --border-color: #404040;

  /* fonts */
  --golos-text: 'Golos Text', sans-serif;
}

body{
  background: var(--primary-black);
}

canvas{
  width: 328px !important;
  height: 452px !important;
}

.hiddenCanvasElement, .react-pdf__Page__textContent, .react-pdf__Page__annotations{
  display: none;
}

.react-pdf__Page__svg{
  width: 328px !important;
  height: 452px !important;
}

.container {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 16px 80px 16px;
}

.react-pdf__Page{
  margin-bottom: 8px;
}

.react-pdf__Page:last-child{
  margin-bottom: 0;
}

.react-pdf__Page__svg{
  border-radius: 8px;
}

@media(max-width: 475px){
  .react-pdf__Page__svg{
    width: 100% !important;
    height: auto !important;
  }
}
