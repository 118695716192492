.errorWrapper {
  margin-top: 40px;
}

.errorBtn {
  padding-left: 10px;
  margin-bottom: 10px;

  & svg {
    position: absolute;
    top: 19px;
    left: 0px;
  }
}

.titleBox {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 40px;
}

.errorTitle {
  max-width: 329px !important;

  & span {
    color: var(--primary-yellow);
  }
}

.errorDescr {
  max-width: 328px;
  font-family: var(--golos-text);
  font-size: 14px;
  line-height: 101%;
  color: var(--white-color);

  & a{
    font-weight: 700;
    color: inherit;
  }
}
