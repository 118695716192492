.adsWrapper {
  margin-top: 40px;
  color: var(--white-color);
  font-family: var(--golos-text);
  font-size: 17px;

  h3 {
    font-weight: 600;
    text-align: center;
    margin: 30px 0;
  }

  h4 {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: 17px;

    li {
      list-style-type: disc;
    }
  }

  p {
    font-size: 17px;
    font-weight: 400;
    span {
      &:last-child {
        font-weight: 400;
      }
    }
  }

  a{
    color: var(--white-color);
    text-decoration: underline;
    font-weight: 600;
  }
}

.adsBtn {
  padding-left: 10px;
  margin-bottom: 10px;

  & svg {
    position: absolute;
    top: 19px;
    left: 0px;
  }
}

.updateDate {
  margin: 20px 0;
}

.adsTitle{
  max-width: 450px;
}

.nestedList{
  margin: 5px 0 0 20px;
  li{
    list-style-type: none !important;
  }
}