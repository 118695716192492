.documentWrapper {
  margin-top: 40px;
}

.documentBtn {
  padding-left: 10px;
  margin-bottom: 10px;

  & svg {
    position: absolute;
    top: 19px;
    left: 0px;
  }
}

.documentTitle{
  word-break: break-all;
}

.documentsButtons {
  display: flex;
  width: 328px;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  & button {
    height: 43px;
  }
}

.documentsBtn {
  width: 277px;
  padding: 8px 10px;
}

.documentImg {
  border-radius: 8px;
  object-fit: cover;
  margin-top: 40px;
}

.paymentLink{
  display: block;
  width: 330px;
  min-height: 43px;
  border-radius: 8px;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--golos-text);
  background: var(--primary-yellow);
  color: var(--primary-black);
  margin-top: 40px;
  text-align: center;
}

.documentComponent{
  width: 328px;
  margin-top: 40px;
}

.documentPage{
  min-width: auto !important;
  min-height: auto !important;
  height: 452px !important;
}





@media (max-width: 475px) {
  .documentWrapper {
    // width: 328px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .documentNavigation{
    display: none;
  }

  .documentImg {
    width: 100%;
  }

  .documentsButtons {
    width: 100%;
    column-gap: 5px;
  }

  .documentComponent{
    width: 100%;
  }

  .documentsBtn {
    width: 87%;
  }

  .paymentLink{
    width: 100%;
  }
}

.documentPreloader{
  display: block !important;
  margin: 0 auto !important;
  margin-top: 250px !important;
}

.docError{
  font-family: var(--golos-text);
  font-size: 14px;
  font-weight: 500;
  color: var(--white-color);
  margin-top: 20px;
}

@media (max-width: 335px) {
  .documentsBtn {
    width: 235px;
  }
}

@media(max-width: 992px) and (orientation: landscape){
  .documentNavigation{
    display: none;
  }
}
