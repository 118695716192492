.listWrapper{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media(max-width: 490px){
  .listWrapper{
    width: 100%;
  }
}