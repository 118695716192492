.paymentsWrapper{
  margin-top: 40px;
}

.paymentsBtn {
  padding-left: 10px;
  margin-bottom: 10px;

  & svg {
    position: absolute;
    top: 19px;
    left: 0px;
  }
}

.allBtn{
  width: 50px;
}

.paymentsFilters{
  position: relative;
  display: flex;
  column-gap: 10px;
  margin: 40px 0 20px 0;

  & button{
    min-height: 0;
    height: 39px;
    color: #aaaaab;
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

.active{
  color: var(--primary-black) !important;
}

.infoSpan {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -6px;
  right: -6px;
  background: #35301b;
  font-family: var(--golos-text);
  font-weight: 500;
  font-size: 10px;
  line-height: 9.4px;
  color: var(--primary-yellow)
}

.preloader{
  display: block !important;
  margin-top: 100px !important;
  margin-left: 100px !important;
}

@media(max-width: 475px){
  .preloader{
    margin-left: 150px !important;
  }
}