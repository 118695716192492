.approvalWrapper {
  margin-top: 40px;
  color: var(--white-color);
  font-family: var(--golos-text);

  h3 {
    font-weight: 600;
    text-align: center;
    margin: 30px 0;
  }

  h4{
    text-align: right;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-size: 17px;

    li {
      list-style-type: disc;
    }
  }

  p{
    font-size: 17px;
    font-weight: 600;
     span{
      &:last-child{
        font-weight: 400;
      }
     }
  }
}

.approvalBtn {
  padding-left: 10px;
  margin-bottom: 10px;

  & svg {
    position: absolute;
    top: 19px;
    left: 0px;
  }
}

.updateDate{
  margin: 20px 0;
}

.approvalText {
  font-family: var(--golos-text);
  color: var(--white-color);
  margin-top: 40px;
}

.approvalSpan {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 10px;
  font-weight: 600;

  ul{
    margin-left: 20px;
  }

  li{
    font-weight: 400;
  }
}

.fullList{
  display: flex;
  flex-direction: column;
}

.approvalInfo{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.approveDescr{
  font-weight: 400 !important;
}

.approveTable{
  width: 100%;
  margin-top: 10px;

  th, td{
    border: 1px solid var(--white-color);
  }

  td{
    padding: 5px 10px;
    font-size: 15px;
  }
}

@media(max-width: 465px){
  .approvalWrapper{
    padding: 0 10px;
  }
}